.article-heading {
  margin: 5em 3rem 3em 3rem;
  text-align: center;
  width: 100%;
  font-family: "Helvetica Now Micro";
  font-weight: normal;
  font-size: 0.9em;
  line-height: 2.5em;
}

/* .section {
  margin: 6em 0;
} */

.articles {
  margin-bottom: 8em;
  max-width: 954px;
  margin: auto;
}

.container-about {
  width: 100vw;
  min-height: calc(100vh - 300px);
}
