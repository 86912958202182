/* General container styling */
.markdown {
  background-color: #f2f2f2 !important;
  line-height: 1.6;
  color: #333; /* Default text color */
  padding: 20px;
  border-radius: 8px;
  margin: auto;
  font-family: "Helvetica Now Micro";
  font-weight: normal;
}

.markdown .header {
  width: 100%;
  padding: 6em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6em;
}

.markdown .image-header img {
  object-fit: cover;
  width: 100%;
  height: auto;
  max-height: 600px;
  margin: 0;
}

.title-subheader {
  font-size: 0.9em;
  margin-bottom: 2em;
  text-align: center;
}

.markdown .body {
  max-width: 720px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  padding-bottom: 2em;
  border-bottom: 1px dotted #dadada;
}

.markdown .details {
  margin: 1em 1em;
  text-align: center;
  font-size: 0.9em;
}

.markdown .details p {
  display: flex;
  justify-content: center;
  font-weight: 500;
}

.markdown .details h3 {
  display: flex;
  justify-content: center;
  font-weight: 0 !important;
  color: #717171;
}

.markdown .details-container {
  margin-top: 2em;
  font-size: 0.9em;
  justify-content: center;
}

/* Header styles */
.markdown h1 {
  font-family: "Salvatore Book";
  font-weight: 500;
  font-size: 32px;
  color: #333;
  text-align: center;
  letter-spacing: normal;
  line-height: 49px;
  width: 100%;
  max-width: 544px;

  font-style: italic;
}

.markdown h2 {
  font-size: 1.4em;
  font-weight: 500;
  margin-bottom: 15px;
  margin: 2em 0 0;
  padding-bottom: 1em;
  border-bottom: #dadada 1px dotted;
  max-width: 720px;
  display: flex;
  align-items: left;
  width: 100%;
}

.markdown .ending-line {
  border-bottom: #dadada 1px dotted;
  max-width: 720px;
  text-align: center;
  width: 100%;
}

/* Paragraph styles */
.markdown .body p {
  font-size: 0.9em;
  color: #3f3f3f;
  margin: 0;
  text-align: left;
  width: 100%;
}

/* Image styles */
.markdown img {
  height: 540px;
  border-radius: 12px; /* Rounded corners for images */
  max-width: 720px;
  height: auto;
  max-height: 500px;
  text-align: center;
  width: 100%;
  object-fit: cover;
  margin: 4em 0;
}

@media screen and (max-width: 1200px) {
  .markdown .image-header img {
    max-height: 400px;
    border-radius: 12px;
    object-fit: cover;
    width: calc(100vw - 40px);
  }

  .markdown img {
    margin: 2em 0;
  }

  .markdown .header {
    width: 100%;
    flex-wrap: wrap;
    padding: 3em;
    gap: 3em;
    min-height: unset;
    height: auto;
  }
}
