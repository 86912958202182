.work-item {
  width: 100%;
  content: flex;
  flex-direction: row;
  border-top: 1px solid #141414;
}

.project-right-hand {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 15px;
  display: grid;
}

.project-left-hand {
  padding-left: 10px;
}

.left-hand-content {
  align-content: flex-start;
  height: 100%;
  position: sticky;
  will-change: transform;
  margin-top: 15px;
  top: 15px;
  margin-bottom: 15px;
  height: min-content;
  padding-right: 15px;
}

.project-title {
  width: 100%;
  color: #141414;
  font-family: "Salvatore Book";
  font-weight: bolder;
  margin-left: 6px;
  margin-bottom: 0.6em;
  margin-top: 0.6em;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: "Salvatore Book";
  font-weight: 300;
  font-style: normal;
  font-size: 0.9em;
}

.project-description,
.project-bullet {
  font-family: "Helvetica Now Micro";
  font-weight: normal;
  margin-left: 6px;
  line-height: 1.7;
  width: 100%;
}

.project-description {
  margin-bottom: 0.9em;
  font-size: 0.8em;
}

.project-bullet {
  width: 100%;
  font-size: 0.8em;
}
@media only screen and (min-width: 1099px) {
  .project-right-hand {
    max-height: 600px;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.03); */
    align-items: center;
    justify-content: center;
    max-height: 100vh;
    width: 110rem;
  }

  .project-right-hand img {
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.03);
    height: auto;
    display: block;
    min-height: 28vh;
    max-height: 90vh;
  }

  .project-left-hand {
    width: 41.6666666667%;
  }
}

@media (max-width: 1099px) {
  .work-item {
    padding: 0;
    content: flex;
    flex-direction: column;
    width: 100%;
  }

  .left-hand-content {
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0;
  }

  .project-left-hand {
    width: 100%;
    font-size: 0.9em;
  }

  .project-right-hand {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 1rem;
    margin-top: 0;
    padding-bottom: 1rem;
    background-color: rgba(0, 0, 0, 0.03);
    margin-bottom: 10px;
  }
}
