@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Nimbus Sans L";
  src: url("/fonts/NimbusSanL-Bol.eot");
  src: local("Nimbus Sans L Bold"), local("NimbusSanL-Bol"),
    url("/fonts/NimbusSanL-Bol.woff2") format("woff2"),
    url("/fonts/NimbusSanL-Bol.woff") format("woff"),
    url("/fonts/NimbusSanL-Bol.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Micro";
  src: url("/fonts/HelveticaNowMicro-ExtBdIta.eot");
  src: local("Helvetica Now Micro ExtBd Ita"),
    local("HelveticaNowMicro-ExtBdIta"),
    url("/fonts/HelveticaNowMicro-ExtBdIta.woff2") format("woff2"),
    url("/fonts/HelveticaNowMicro-ExtBdIta.woff") format("woff"),
    url("/fonts/HelveticaNowMicro-ExtBdIta.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Micro";
  src: url("/fonts/HelveticaNowMicro-Light.eot");
  src: local("Helvetica Now Micro Light"), local("HelveticaNowMicro-Light"),
    url("/fonts/HelveticaNowMicro-Light.woff2") format("woff2"),
    url("/fonts/HelveticaNowMicro-Light.woff") format("woff"),
    url("/fonts/HelveticaNowMicro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Micro";
  src: url("/fonts/HelveticaNowMicro-MedIta.eot");
  src: local("Helvetica Now Micro Med Ita"), local("HelveticaNowMicro-MedIta"),
    url("/fonts/HelveticaNowMicro-MedIta.woff2") format("woff2"),
    url("/fonts/HelveticaNowMicro-MedIta.woff") format("woff"),
    url("/fonts/HelveticaNowMicro-MedIta.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Micro";
  src: url("/fonts/HelveticaNowMicro-ExtLtIta.eot");
  src: local("Helvetica Now Micro ExtLt Ita"),
    local("HelveticaNowMicro-ExtLtIta"),
    url("/fonts/HelveticaNowMicro-ExtLtIta.woff2") format("woff2"),
    url("/fonts/HelveticaNowMicro-ExtLtIta.woff") format("woff"),
    url("/fonts/HelveticaNowMicro-ExtLtIta.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Micro";
  src: url("/fonts/HelveticaNowMicro-ExtraLight.eot");
  src: local("Helvetica Now Micro Extra Light"),
    local("HelveticaNowMicro-ExtraLight"),
    url("/fonts/HelveticaNowMicro-ExtraLight.woff2") format("woff2"),
    url("/fonts/HelveticaNowMicro-ExtraLight.woff") format("woff"),
    url("/fonts/HelveticaNowMicro-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Micro";
  src: url("/fonts/HelveticaNowMicro-LightItalic.eot");
  src: local("Helvetica Now Micro Light Ita"),
    local("HelveticaNowMicro-LightItalic"),
    url("/fonts/HelveticaNowMicro-LightItalic.woff2") format("woff2"),
    url("/fonts/HelveticaNowMicro-LightItalic.woff") format("woff"),
    url("/fonts/HelveticaNowMicro-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Micro";
  src: url("/fonts/HelveticaNowMicro-Medium.eot");
  src: local("Helvetica Now Micro Medium"), local("HelveticaNowMicro-Medium"),
    url("/fonts/HelveticaNowMicro-Medium.woff2") format("woff2"),
    url("/fonts/HelveticaNowMicro-Medium.woff") format("woff"),
    url("/fonts/HelveticaNowMicro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Micro";
  src: url("/fonts/HelveticaNowMicro-RegIta.eot");
  src: local("Helvetica Now Micro Reg Ita"), local("HelveticaNowMicro-RegIta"),
    url("/fonts/HelveticaNowMicro-RegIta.woff2") format("woff2"),
    url("/fonts/HelveticaNowMicro-RegIta.woff") format("woff"),
    url("/fonts/HelveticaNowMicro-RegIta.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Micro";
  src: url("/fonts/HelveticaNowMicro-BoldItalic.eot");
  src: local("Helvetica Now Micro Bold Italic"),
    local("HelveticaNowMicro-BoldItalic"),
    url("/fonts/HelveticaNowMicro-BoldItalic.woff2") format("woff2"),
    url("/fonts/HelveticaNowMicro-BoldItalic.woff") format("woff"),
    url("/fonts/HelveticaNowMicro-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Micro";
  src: url("/fonts/HelveticaNowMicro-Bold.eot");
  src: local("Helvetica Now Micro Bold"), local("HelveticaNowMicro-Bold"),
    url("/fonts/HelveticaNowMicro-Bold.woff2") format("woff2"),
    url("/fonts/HelveticaNowMicro-Bold.woff") format("woff"),
    url("/fonts/HelveticaNowMicro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Micro";
  src: url("/fonts/HelveticaNowMicro-Regular.eot");
  src: local("Helvetica Now Micro Regular"), local("HelveticaNowMicro-Regular"),
    url("/fonts/HelveticaNowMicro-Regular.woff2") format("woff2"),
    url("/fonts/HelveticaNowMicro-Regular.woff") format("woff"),
    url("/fonts/HelveticaNowMicro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Micro";
  src: url("/fonts/HelveticaNowMicro-ExtraBold.eot");
  src: local("Helvetica Now Micro Extra Bold"),
    local("HelveticaNowMicro-ExtraBold"),
    url("/fonts/HelveticaNowMicro-ExtraBold.woff2") format("woff2"),
    url("/fonts/HelveticaNowMicro-ExtraBold.woff") format("woff"),
    url("/fonts/HelveticaNowMicro-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Neue Plak Extended";
  src: url("/fonts/NeuePlak-ExtendedBold.eot");
  src: local("Neue Plak Extended Bold"), local("NeuePlak-ExtendedBold"),
    url("/fonts/NeuePlak-ExtendedBold.woff2") format("woff2"),
    url("/fonts/NeuePlak-ExtendedBold.woff") format("woff"),
    url("/fonts/NeuePlak-ExtendedBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Plak Extended Ultra";
  src: url("/fonts/NeuePlak-ExtendedUltraLight.eot");
  src: local("Neue Plak Extended Ultra Light"),
    local("NeuePlak-ExtendedUltraLight"),
    url("/fonts/NeuePlak-ExtendedUltraLight.woff2") format("woff2"),
    url("/fonts/NeuePlak-ExtendedUltraLight.woff") format("woff"),
    url("/fonts/NeuePlak-ExtendedUltraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Plak Extended";
  src: url("/fonts/NeuePlak-ExtendedBlack.eot");
  src: local("Neue Plak Extended Black"), local("NeuePlak-ExtendedBlack"),
    url("/fonts/NeuePlak-ExtendedBlack.woff2") format("woff2"),
    url("/fonts/NeuePlak-ExtendedBlack.woff") format("woff"),
    url("/fonts/NeuePlak-ExtendedBlack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Plak Extended";
  src: url("/fonts/NeuePlak-ExtendedLight.eot");
  src: local("Neue Plak Extended Light"), local("NeuePlak-ExtendedLight"),
    url("/fonts/NeuePlak-ExtendedLight.woff2") format("woff2"),
    url("/fonts/NeuePlak-ExtendedLight.woff") format("woff"),
    url("/fonts/NeuePlak-ExtendedLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Plak Extended";
  src: url("/fonts/NeuePlak-ExtendedThin.eot");
  src: local("Neue Plak Extended Thin"), local("NeuePlak-ExtendedThin"),
    url("/fonts/NeuePlak-ExtendedThin.woff2") format("woff2"),
    url("/fonts/NeuePlak-ExtendedThin.woff") format("woff"),
    url("/fonts/NeuePlak-ExtendedThin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Plak Extended Semi";
  src: url("/fonts/NeuePlak-ExtendedSemiBold.eot");
  src: local("Neue Plak Extended Semi Bold"), local("NeuePlak-ExtendedSemiBold"),
    url("/fonts/NeuePlak-ExtendedSemiBold.woff2") format("woff2"),
    url("/fonts/NeuePlak-ExtendedSemiBold.woff") format("woff"),
    url("/fonts/NeuePlak-ExtendedSemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Plak Extended";
  src: url("/fonts/NeuePlak-ExtendedRegular.eot");
  src: local("Neue Plak Extended"), local("NeuePlak-ExtendedRegular"),
    url("/fonts/NeuePlak-ExtendedRegular.woff2") format("woff2"),
    url("/fonts/NeuePlak-ExtendedRegular.woff") format("woff"),
    url("/fonts/NeuePlak-ExtendedRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Plak Extended Extra";
  src: url("/fonts/NeuePlak-ExtendedExtraBlack.eot");
  src: local("Neue Plak Extended Extra Black"),
    local("NeuePlak-ExtendedExtraBlack"),
    url("/fonts/NeuePlak-ExtendedExtraBlack.woff2") format("woff2"),
    url("/fonts/NeuePlak-ExtendedExtraBlack.woff") format("woff"),
    url("/fonts/NeuePlak-ExtendedExtraBlack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Salvatore";
  src: url("/fonts/Salvatore-ThinItalic.eot");
  src: local("Salvatore Thin Italic"), local("Salvatore-ThinItalic"),
    url("/fonts/Salvatore-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Salvatore-ThinItalic.woff2") format("woff2"),
    url("/fonts/Salvatore-ThinItalic.woff") format("woff"),
    url("/fonts/Salvatore-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Salvatore";
  src: url("/fonts/Salvatore-Thin.eot");
  src: local("Salvatore Thin"), local("Salvatore-Thin"),
    url("/fonts/Salvatore-Thin.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Salvatore-Thin.woff2") format("woff2"),
    url("/fonts/Salvatore-Thin.woff") format("woff"),
    url("/fonts/Salvatore-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Salvatore";
  src: url("/fonts/Salvatore-RegularItalic.eot");
  src: local("Salvatore Regular Italic"), local("Salvatore-RegularItalic"),
    url("/fonts/Salvatore-RegularItalic.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Salvatore-RegularItalic.woff2") format("woff2"),
    url("/fonts/Salvatore-RegularItalic.woff") format("woff"),
    url("/fonts/Salvatore-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Salvatore";
  src: url("/fonts/Salvatore-UltraLight.eot");
  src: local("Salvatore UltraLight"), local("Salvatore-UltraLight"),
    url("/fonts/Salvatore-UltraLight.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Salvatore-UltraLight.woff2") format("woff2"),
    url("/fonts/Salvatore-UltraLight.woff") format("woff"),
    url("/fonts/Salvatore-UltraLight.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Salvatore Book";
  src: url("/fonts/Salvatore-BookItalic.eot");
  src: local("Salvatore Book Italic"), local("Salvatore-BookItalic"),
    url("/fonts/Salvatore-BookItalic.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Salvatore-BookItalic.woff2") format("woff2"),
    url("/fonts/Salvatore-BookItalic.woff") format("woff"),
    url("/fonts/Salvatore-BookItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Salvatore";
  src: url("/fonts/Salvatore-ExtraBoldItalic.eot");
  src: local("Salvatore ExtraBold Italic"), local("Salvatore-ExtraBoldItalic"),
    url("/fonts/Salvatore-ExtraBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/Salvatore-ExtraBoldItalic.woff2") format("woff2"),
    url("/fonts/Salvatore-ExtraBoldItalic.woff") format("woff"),
    url("/fonts/Salvatore-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Salvatore";
  src: url("/fonts/Salvatore-ExtraBold.eot");
  src: local("Salvatore ExtraBold"), local("Salvatore-ExtraBold"),
    url("/fonts/Salvatore-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Salvatore-ExtraBold.woff2") format("woff2"),
    url("/fonts/Salvatore-ExtraBold.woff") format("woff"),
    url("/fonts/Salvatore-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Salvatore";
  src: url("/fonts/Salvatore-BoldItalic.eot");
  src: local("Salvatore Bold Italic"), local("Salvatore-BoldItalic"),
    url("/fonts/Salvatore-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Salvatore-BoldItalic.woff2") format("woff2"),
    url("/fonts/Salvatore-BoldItalic.woff") format("woff"),
    url("/fonts/Salvatore-BoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Salvatore";
  src: url("/fonts/Salvatore-UltraLightItalic.eot");
  src: local("Salvatore UltraLight Italic"), local("Salvatore-UltraLightItalic"),
    url("/fonts/Salvatore-UltraLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/Salvatore-UltraLightItalic.woff2") format("woff2"),
    url("/fonts/Salvatore-UltraLightItalic.woff") format("woff"),
    url("/fonts/Salvatore-UltraLightItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Salvatore";
  src: url("/fonts/Salvatore-Bold.eot");
  src: local("Salvatore Bold"), local("Salvatore-Bold"),
    url("/fonts/Salvatore-Bold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Salvatore-Bold.woff2") format("woff2"),
    url("/fonts/Salvatore-Bold.woff") format("woff"),
    url("/fonts/Salvatore-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Salvatore";
  src: url("/fonts/Salvatore-Black.eot");
  src: local("Salvatore Black"), local("Salvatore-Black"),
    url("/fonts/Salvatore-Black.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Salvatore-Black.woff2") format("woff2"),
    url("/fonts/Salvatore-Black.woff") format("woff"),
    url("/fonts/Salvatore-Black.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Salvatore UltraBlack";
  src: url("/fonts/Salvatore-UltraBlackItalic.eot");
  src: local("Salvatore UltraBlack Italic"), local("Salvatore-UltraBlackItalic"),
    url("/fonts/Salvatore-UltraBlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/Salvatore-UltraBlackItalic.woff2") format("woff2"),
    url("/fonts/Salvatore-UltraBlackItalic.woff") format("woff"),
    url("/fonts/Salvatore-UltraBlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Salvatore Book";
  src: url("/fonts/Salvatore-Book.eot");
  src: local("Salvatore Book"), local("Salvatore-Book"),
    url("/fonts/Salvatore-Book.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Salvatore-Book.woff2") format("woff2"),
    url("/fonts/Salvatore-Book.woff") format("woff"),
    url("/fonts/Salvatore-Book.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Salvatore";
  src: url("/fonts/Salvatore-BlackItalic.eot");
  src: local("Salvatore Black Italic"), local("Salvatore-BlackItalic"),
    url("/fonts/Salvatore-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Salvatore-BlackItalic.woff2") format("woff2"),
    url("/fonts/Salvatore-BlackItalic.woff") format("woff"),
    url("/fonts/Salvatore-BlackItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Salvatore";
  src: url("/fonts/Salvatore-LightItalic.eot");
  src: local("Salvatore Light Italic"), local("Salvatore-LightItalic"),
    url("/fonts/Salvatore-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Salvatore-LightItalic.woff2") format("woff2"),
    url("/fonts/Salvatore-LightItalic.woff") format("woff"),
    url("/fonts/Salvatore-LightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Salvatore";
  src: url("/fonts/Salvatore-Regular.eot");
  src: local("Salvatore Regular"), local("Salvatore-Regular"),
    url("/fonts/Salvatore-Regular.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Salvatore-Regular.woff2") format("woff2"),
    url("/fonts/Salvatore-Regular.woff") format("woff"),
    url("/fonts/Salvatore-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Salvatore UltraBlack";
  src: url("/fonts/Salvatore-UltraBlack.eot");
  src: local("/fonts/Salvatore UltraBlack"), local("Salvatore-UltraBlack"),
    url("/fonts/Salvatore-UltraBlack.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Salvatore-UltraBlack.woff2") format("woff2"),
    url("/fonts/Salvatore-UltraBlack.woff") format("woff"),
    url("/fonts/Salvatore-UltraBlack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Salvatore";
  src: url("/fonts/Salvatore-Light.eot");
  src: local("Salvatore Light"), local("Salvatore-Light"),
    url("/fonts/Salvatore-Light.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Salvatore-Light.woff2") format("woff2"),
    url("/fonts/Salvatore-Light.woff") format("woff"),
    url("/fonts/Salvatore-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
