.left-hand-footer {
  margin-left: 6%;
  padding-top: 45px;
  padding-bottom: 45px;
  transition: color 0.3s ease;
}

.footer-links {
  font-family: "Helvetica Now Micro";
  font-weight: normal;
  font-size: 0.9em;
  transition: color 0.3s ease;
}

.footer-links a {
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: black;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #d3d3d3b6 !important;
  transition: color 0.3s ease;
}

.footer-links a span {
  padding-left: 4px;
  padding-top: 3.5px;
}

.made-by {
  font-family: "Helvetica Now Micro";
  font-weight: normal;
  font-size: 0.9em;
  width: 100%;
  margin-right: 6%;
  margin-top: 100px;
  height: 100%;
}

.made-by p a {
  font-size: 1.1em;
  padding-top: 3px;
  padding-left: 5px;
  padding-right: 5px;
  transition: color 0.3s ease;
}

.made-by p a:hover {
  color: #d3d3d3b6 !important;
  transition: color 0.3s ease;
}

.last-updated {
  font-size: 0.75em;
  margin-top: 3px;
  color: #d3d3d3b6;
}

@media (max-width: 768px) {
  .footer {
    display: flex;
    flex-direction: column;
    padding: 30px;
  }

  .left-hand-footer {
    padding-bottom: 28px;
  }

  .made-by {
    margin: 0px;
    margin-left: 6%;
    align-items: start !important;
  }

  .footer-links {
    font-family: "Helvetica Now Micro";
    font-weight: normal;
    font-size: 0.9em;
    transition: color 0.3s ease;
  }

  .footer-links a span {
    padding-left: 4px;
    padding-top: 3.5px;
  }
}
