.article-item-info {
  width: 100%;
}

.muted {
  color: #717171;
  padding-top: 1em;
  padding-bottom: 3px;
}

.article-item-info p.description {
  font-size: 0.9em;
  line-height: 1.6em;
  color: #0f0f0f;
  font-family: "Helvetica Now Micro";
  font-weight: 500;
  border-color: #dadada;
  display: inline-block;
}

.article-item-info .article-details-info {
  margin: 2em 1em 2em 0;
  font-size: 0.95em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.article-details-info.left {
  justify-content: flex-start;
  text-align: left;
}

.article-details-info .article-details p {
  line-height: 1em;
  padding-top: 6%;
  margin: 0;
  margin-right: 2em;
  font-family: "Helvetica Now Micro";
  font-size: 0.8em;
}

.bold {
  font-weight: 500;
  color: #0f0f0f;
}

.article-link {
  background: transparent;
  display: block;
  border: none;
  position: relative;
  z-index: 1;
  padding-left: 1em;
  margin-top: 2em;
  transition: 0.3s ease;
}

.article-link:hover {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.03);
  transition: 0.3s ease;
  transform-origin: center;
}

.article-item {
  padding: 1em;
  display: flex;
  gap: 6em;
  /* margin: 10em 0; */
  justify-content: space-between;
  align-items: center;
}

.article-item .article-photo img {
  width: 800px;
  max-height: 400px;
  object-fit: cover;
  object-position: center;
  display: block;
  border-radius: 12px;
}

.button {
  background-color: black;
  color: white;
  padding: 1.2em 2em;
  box-shadow: 0px 10px 60px -4px #00000033;
  border-radius: 50px;
  font-family: "Helvetica Now Micro";
  font-size: 12.16px;
}

.arrow {
  margin-top: 2.5px;
  margin-left: 5px;
  font-size: 1.1em;
}

@media (max-width: 768px) {
}
